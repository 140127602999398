import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NavService } from '../../shared/services/commonServices';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-faq',
  templateUrl: './faq.component.html',
})
export class FaqComponent implements OnInit {

  public isCollapsed: boolean = true;

  constructor(private _router: Router, private _route: ActivatedRoute, private navsvc: NavService) { }

  public ngOnInit(): void {
    //$('#ember610').removeClass('active');
    this.selectedNavItem(5);
  }

  public selectedNavItem(item: number): void {

    this.navsvc.changeNav(item);
  }

}
