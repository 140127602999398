import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalSearchViews } from '../../shared/models/global-search-views.data.model';
import { PagerService } from '../../shared/pagination/pager.service';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { NavService } from '../../shared/services/commonServices';
import { HeaderService } from '../layout/header.service';
import { HcpcSearchService } from './hcpc-search.service';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-hcpc-search',
  templateUrl: './hcpc-search.component.html',
  styleUrls: ['./hcpc-search.component.scss'],
})
export class HcpcSearchComponent implements OnInit {

  public usertypedValue: string;
  public selectedHcpcCodeDescr: string;
  public selectedHcpcCode: string;
  public isFirstTimeLoad: boolean = true;
  public isFromSuccess: string = '';
  public searchTextChanged: Subject<string> = new Subject<string>();

  public showErrorMesage: boolean = false;
  public errorMessage: string;
  public hcpcsCodes: GlobalSearchViews[] = [];
  public model: { hcpccode: string } = { hcpccode: null };
  public isResultFound: boolean = false;
  public pageindex: number;

  // tslint:disable-next-line: no-any
  public pager: any = {};


  public pagedItems: GlobalSearchViews[] = [];

  public display: string = 'none';


  public isFromNdc: string = 'false';
  public selectedNdcCode: string;
  public selectedNdcCodeDescr: string;
  public reImbCode: string;
  public rcdescr: string;
  public isFromNdcOnPreviousClick: string = '';

  public userTypedValueOnNdc: string = null;
  public selectedHcpcCodefromHcpc: string;
  public selectedHcpcDescrfromHcpc: string;
  public rcId: string = null;
  public accountId: number;
  public showNdcDrugNameSection: boolean;
  public ndcStatus: string = null;
  public rcstatus: string = null;
  public selectedHcpcCodeOnHcpcSearch: string;
  public selectedHcpcDescrOnHcpcSearch: string;
  public url: string;
  public termDate : string =null;
  public screenWidth: number;
  public openPopover: string;

  constructor(
    private data: HcpcSearchService,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private navsvc: NavService,
    private authService: AuthorizationServices,
    private headerService: HeaderService,
  ) { }

  public ngOnInit(): void {
    //$('#ember610').addClass('active');
    this.usertypedValue = '';
    this.model.hcpccode = '';

    this.isResultFound = false;
    this.isFromNdcOnPreviousClick = this.activatedRoute.snapshot.queryParamMap.get('isFromNdcOnPreviousClick');
    this.isFromNdc = this.activatedRoute.snapshot.queryParamMap.get('isFromNdc');
    this.isFromSuccess = this.activatedRoute.snapshot.queryParamMap.get('isFromSuccess');

    this.userTypedValueOnNdc = this.activatedRoute.snapshot.queryParamMap.get('userTypedValue');
    this.selectedHcpcCodefromHcpc = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromHcpc');
    this.selectedHcpcDescrfromHcpc = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromHcpc');
    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.ndcStatus = this.activatedRoute.snapshot.queryParamMap.get('status');
    this.termDate = this.activatedRoute.snapshot.queryParamMap.get('termDate');
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1199) {
      this.openPopover = 'click';
    }
    else {
      this.openPopover = 'mouseenter:mouseleave, click';
    }
    if (this.isFromNdc === 'true' || this.isFromNdcOnPreviousClick) {
      this.isFirstTimeLoad = false;

      this.selectedNdcCode = this.activatedRoute.snapshot.queryParamMap.get('ndccode');
      this.selectedNdcCodeDescr = this.activatedRoute.snapshot.queryParamMap.get('ndcdescr');
      this.reImbCode = this.activatedRoute.snapshot.queryParamMap.get('reImbCode');
      this.rcdescr = this.activatedRoute.snapshot.queryParamMap.get('rcdescr');
      this.rcstatus = this.activatedRoute.snapshot.queryParamMap.get('rcstatus');

      this.model.hcpccode = this.activatedRoute.snapshot.queryParamMap.get('reImbCode');
      if (this.isFromNdcOnPreviousClick) {
        this.model.hcpccode = this.activatedRoute.snapshot.queryParamMap.get('userTypedValue');
      }
      this.getListOfJcodeValue(this.model.hcpccode);
    }
    else if (this.isFromSuccess === 'true') {
      this.isFirstTimeLoad = false;
      this.selectedNdcCode = this.activatedRoute.snapshot.queryParamMap.get('ndccode');
      this.selectedNdcCodeDescr = this.activatedRoute.snapshot.queryParamMap.get('ndcdescr');
      this.reImbCode = this.activatedRoute.snapshot.queryParamMap.get('reImbCode');
      this.rcdescr = this.activatedRoute.snapshot.queryParamMap.get('rcdescr');
      this.rcstatus = this.activatedRoute.snapshot.queryParamMap.get('hcpcStatus');
      this.ndcStatus = this.activatedRoute.snapshot.queryParamMap.get('status');

    }
    this.isSessionActive();
    this.searchTextChanged.pipe(debounceTime(1000))

      .subscribe((x) => this.getListOfJcodeValue(x));
  }

  public callOnKeyUp(e: Event): void {
    if ((e['which'] < 48 || e['which'] > 105) && e['which'] !== 8 && e['which'] !== 16) {
      return;
    }
    if (e['which'] === 91 || e['which'] === 92 || e['which'] === 93) {
      return;
    }
    this.hcpcsCodes = [];
    this.pagedItems = [];
    this.pager = {};
    this.isResultFound = false;
    this.selectedNavItem(1);
    if (this.model.hcpccode.length >= 3) {
      this.searchTextChanged.next(e.target['value']);

    }
    else {
      return;
    }
  }
  public selectedNavItem(item: number): void {
    this.navsvc.changeNav(item);
  }


  public getListOfJcodeValue(x: string): void {
    this.usertypedValue = this.model.hcpccode;
    this.data.getJcodeResponse(this.accountId, x).subscribe(data => {
      if (data === null) {
        this.isResultFound = true;
        return;
      }
      this.filterUnwantedData(Object.assign([], data));

    },
      error => {
        this.isResultFound = true;
      });
  }


  public clearModel(): void {
    setTimeout((): void => { $('.cancel-icon').toggleClass('rotate'); }, 80);
    this.model.hcpccode = '';
    this.hcpcsCodes = [];
    this.pagedItems = [];
    this.pager = {};
    this.isResultFound = false;
    this.usertypedValue = null;
  }

  public filterUnwantedData(hcpcsCodesList: GlobalSearchViews[]): void {

    if (hcpcsCodesList !== undefined) {
      if (this.isFirstTimeLoad || this.isFromNdcOnPreviousClick === 'true') {
        this.selectedNavItem(1);
      }
      this.hcpcsCodes = [];
      for (let obj of hcpcsCodesList) {
        if (obj.reimb_code && (obj.rc_descr || obj.rc_desc)) {
          this.hcpcsCodes.push(obj);
        }
      }

      this.hcpcsCodes = this.removeDuplicate(this.hcpcsCodes);
      this.hcpcsCodes.sort((a, b) => a.reimb_code.localeCompare(b.reimb_code));
      this.hcpcsCodes.sort((a, b) => a.sort_order.localeCompare(b.sort_order));
      this.isResultFound = !(this.hcpcsCodes.length > 0);
      this.setPage(1);
    } else {
      this.isResultFound = true;
    }

  }

  public setHcpcCode(index: number): void {

    if (this.isFirstTimeLoad === true || this.isFromNdcOnPreviousClick) {
      this.selectedHcpcCode = this.pagedItems[index].reimb_code;
      this.selectedHcpcCodeDescr = this.pagedItems[index].rc_descr;
      this.isFirstTimeLoad = false;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          hcpccode: this.selectedHcpcCode,
          hcpcdescr: this.selectedHcpcCodeDescr,
          isFromHCpc: 'true',
          rcId: this.pagedItems[index].rc_id,
          usertypedValue: this.usertypedValue,
          hcpcStatus: this.pagedItems[index].rc_status,
        },
      };
      this.router.navigate(['/layout/ndcsearch'], navigationExtras);

    }


    else if (this.isFromNdc === 'true' || this.isFromSuccess === 'true') {
      this.selectedHcpcCode = this.reImbCode;
      this.selectedHcpcCodeDescr = this.rcdescr;
      this.isFirstTimeLoad = false;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          selectedNdcCode: this.selectedNdcCode,
          selectedNdcCodeDescr: this.selectedNdcCodeDescr,
          hcpccode: this.selectedHcpcCode,
          hcpcdescr: this.selectedHcpcCodeDescr,
          isFromHCpc: 'true',
          status: this.ndcStatus,
          hcpcStatus: this.rcstatus,
          usertypedValueonNdc: this.userTypedValueOnNdc,
        },
      };
      this.router.navigate(['layout/success'], navigationExtras);
    }
  }

  public setPage(page: number): void {
    if (page < 1) {
      return;
    }
    if (page !== 1 && page > this.pager.totalPages) {
      return;
    }
    this.pageindex = page;
    // get pager object from service
    this.pager = this.pagerService.getPager(this.hcpcsCodes.length, page);

    // get current page of items
    this.pagedItems = this.hcpcsCodes.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  public redirectToNdcOnPreviousClick(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        userTypedValueOnNdc: this.userTypedValueOnNdc,
        isFromHCpcOnPreviousClick: 'true',
      },
    };
    this.router.navigate(['/layout/ndcsearch'], navigationExtras);
  }

  public removeDuplicate(origArr: GlobalSearchViews[]): GlobalSearchViews[] {
    var newArr: GlobalSearchViews[] = [];
    var origLen = origArr.length;
    var found, x, y;

    for (x = 0; x < origLen; x++) {
      found = undefined;
      for (y = 0; y < newArr.length; y++) {
        if (origArr[x].reimb_code === newArr[y].reimb_code &&
          origArr[x].rc_status === newArr[y].rc_status && origArr[x].rc_descr === newArr[y].rc_descr) {
          found = true;
          break;
        }
      }
      if (!found) {
        if (origArr[x] === undefined) {
          continue;
        }
        newArr.push(origArr[x]);
      }
    }
    return newArr;
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }

  public openModal(): void {
    this.display = 'block';
  }

  public onCloseHandled(): void {
    if (window.name !== '' && JSON.parse(window.name).session_id !== undefined && sessionStorage.isFixedUrl === 'false') {
      this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;

      this.headerService.deleteSession(JSON.parse(window.name).session_id).subscribe(response => {
        this.clearSession();
      },
        error => {
          this.clearSession();
        });
    }
    else {
      this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
      sessionStorage.clear();
      location.replace(this.url);
    }
  }

  public clearSession(): void {
    window.name = '';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    sessionStorage.removeItem('config');
    location.replace(this.url);
  }

}
