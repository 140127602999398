import { ChangeDetectorRef, Component } from '@angular/core';
import { Router } from '@angular/router';
import * as LogRocket from 'logrocket';
import { Observable, Subscription, timer } from 'rxjs';
import { AccountOutput } from 'src/app/shared/models/account-output.data.model';
import { AccountDetailsModel } from 'src/app/shared/models/AccountDetailsModel';
import { EnvironmentData } from 'src/app/shared/models/environment.data.model';
import { AccountDetails } from 'src/app/shared/services/account-details.prod';
import { AuthorizationServices } from 'src/app/shared/services/authorizationServices';
import { CheckAuthService } from 'src/app/shared/services/checkAuth.service';
import { Logger } from 'src/app/shared/services/logger.service';
import { ToastService } from 'src/app/shared/services/toast.service';

@Component({
  selector: 'app-unauth',
  templateUrl: './unauth.component.html',
})

export class UnauthComponent {

  public error: string = '';
  public config: EnvironmentData;
  public selectedAccountId: string = '';
  // public encKey: string = '';
  public sessionId: string = '';
  public dateTimePassedByAma: string = new Date().toString();
  public showUserDropdown: boolean = false;
  public accountDetails: AccountDetailsModel[] = AccountDetails;
  private subscription: Subscription;
  private refreshTime: Observable<number> = timer(0, 60000);

  constructor(
    private cdr: ChangeDetectorRef,
    private authService: AuthorizationServices,
    private router: Router,
    private logger: Logger,
    private toastService: ToastService,
    private service: CheckAuthService
  ) {
  }
  public ngOnInit(): void {
    this.sessionId = sessionStorage.getItem('uid');
    let accessKey = sessionStorage.getItem("ak") ? sessionStorage.getItem("ak") : null;
		if (!accessKey) {
			this.loadConfig()
		} else {
      this.getConfiguration();
    }
  }

  private getConfiguration(): void {
    this.authService.getConfig().subscribe(res => {
      this.config = res;
      sessionStorage.setItem('config', JSON.stringify(res));
      this.sessionIdleCheck();
      if (!this.cdr['destroyed']) this.cdr.detectChanges();
      // if (this.encKey && this.encKey !== 'null') {
      //   this.validateRequest();
      // }
      if (this.sessionId && this.sessionId !== 'null') {
        this.validateRequest();
      }

    }, (err: Response) => {
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);
    });
  }

  private validateRequest(): void {
    this.authService.checkSession(this.sessionId).subscribe(res => {
      console.log("Unauth Validate Request")
      this.selectedAccountId = res['account_id'];
      this.getAccountDetails(this.selectedAccountId);
    }, (err: Response) => {
      sessionStorage.clear();
      sessionStorage.setItem('config', JSON.stringify(this.config));
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);
    });

    /* var keyObject = { key: this.encKey };

    this.authService.decryptMessage(keyObject).subscribe(data => {
      let userValues = data.toString().split('/');
      if (userValues) {
        sessionStorage['encKey'] = null;
        this.dateTimePassedByAma = userValues[0];
        this.getAccountDetails(userValues[1]);
      }
    }, (err: Response) => {
      this.logger.error(err['error'].message);
      this.showDanger('error in service:' + err['error'].message);
    }); */
  }

  public getAccountDetails(accountId: string): void {
    this.authService.getAccountDetails(accountId).subscribe(res => {
      // this.checkSSOUrl(res[0]);
      let amaDetails = res[0];
      if (amaDetails['sso_redirect_url']) {
        console.log(amaDetails['sso_redirect_url']);
        window.location.replace(amaDetails['sso_redirect_url']);
      } else {
        var loggedInDateTime = new Date(this.dateTimePassedByAma);
        var currentDateTime = new Date(res['current_date_time']);
        loggedInDateTime.setMinutes(loggedInDateTime.getMinutes() + 10);
        if (loggedInDateTime < currentDateTime) {
          this.showDanger('Login token time has expired/Invalid token.');
          return;
        }
        sessionStorage['isValidUser'] = true;
        sessionStorage.setItem('accountDetails', JSON.stringify(res[0]));
        if (!this.cdr['destroyed']) this.cdr.detectChanges();
        this.logRocket(res[0]);
        sessionStorage.setItem('showFirstTimeWelcome', 'true');
        this.router.navigate(['/layout']);
      }
    }, (err: Response) => {
      this.showDanger('error in service:' + err['error'].message);
      this.logger.error(err['error'].message);
    });

  }

  public checkSSOUrl(amaDetails: object): void {
    if (amaDetails['sso_redirect_url']) {
      console.log(amaDetails['sso_redirect_url']);
      // window.location.replace(amaDetails['sso_redirect_url']);
    }
  }


  public sessionIdleCheck(): void {
    this.subscription = this.refreshTime.subscribe((seconds) => {
      this.authService.getConfig().subscribe(res => {
        if (res['ApplicationVersionNo'] !== (JSON.parse(sessionStorage.getItem('config'))).ApplicationVersionNo) {
          this.config = res;
          sessionStorage.setItem('config', JSON.stringify(res));
          this.logger.debug('Refreshed config');
        }
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    });
  }

  public logRocket(accountDetails: AccountOutput): void {
    if (!this.config) {
      this.authService.getConfig().subscribe(res => {
        sessionStorage.setItem('config', JSON.stringify(res));
        this.identifyLogRocket(accountDetails, res['CurrentEnvironment']);
      }, (err: Response) => {
        this.logger.error(err['error'].message);
        this.showDanger('error in service:' + err['error'].message);
      });
    } else {
      this.identifyLogRocket(accountDetails, this.config['CurrentEnvironment']);
    }
  }

  private identifyLogRocket(accountDetails: AccountOutput, env: string): void {
    // only enable LR for UAT env
    if (env === 'UAT') {
      // filter out automated testing user
      if (accountDetails.username !== 'AutoFSUser1') {
        LogRocket.init('rj-health/rc3');
        LogRocket.identify('rj-health/rc3', {
          name: accountDetails.username,
          email: accountDetails.email,
        });
      }
    }
  }

  public showDanger(dangerTpl: string): void {
    this.toastService.show(dangerTpl, { classname: 'bg-danger text-light', delay: 15000 });
  }

  public loadConfig(): void {
    this.authService.getConfig().subscribe(res => {
      this.config = res;
      this.service.loadConfig(res.CheckUrl).subscribe(resp => {
        let ak = resp.headers.get('Content-Cf-Ak');
        let sk = resp.headers.get('Content-Cf-Sk');
        let chkAK = atob(ak);
        let chkSK = atob(sk);
        sessionStorage.setItem('ak', chkAK);
        sessionStorage.setItem('sk', chkSK);
        this.getConfiguration();
      }, err => {
        console.log("Error");
      });
    });
  }

}
