import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EnvironmentData } from '../models/environment.data.model';
@Injectable({
  providedIn: 'root',
})
export class CheckAuthService {
  public url: string = '';
  public config: EnvironmentData = JSON.parse(sessionStorage.getItem('config'));
  constructor(public http: HttpClient) { }

  public loadConfig(url: string) {
    this.url = url
    return this.http.head(this.url, {observe: "response"});
  }
}
