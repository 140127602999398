import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { AccountOutput } from '../models/account-output.data.model';
import { EnvironmentData } from '../models/environment.data.model';
import { CommonService } from './common.service';

@Injectable({
  providedIn: 'root',
})

export class LoginService {
  public headers: HttpHeaders = null;
  public configuration: EnvironmentData = JSON.parse(sessionStorage.getItem('config'));
  public sessionId: string = '';
  public browserData = this.commonService.getBrowserDetails();

  constructor(
    private _http: HttpClient,
    private commonService: CommonService,
    private router: Router,
    public snackbar: MatSnackBar
  ) { }

  private setHeaders(): HttpHeaders {
    this.configuration = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.configuration.Authorization);
  }

  errorMessage(message: string) {
    this.snackbar.open(message, '', {
      verticalPosition: 'top',
      horizontalPosition: 'center',
      panelClass: ['error'],
      duration: 5000,
    });
  }
  public getAmaLoginDetails(jsonData: object): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/amalogin', jsonData, { headers });
  }

  // Login Services

  rc_navigateApplication(pass: string, username: string, verificationKey: string, amaLoginResponse: object) {
    let resp = amaLoginResponse[0];
    let product_name = resp.website_product_name
    var uuId = resp.session_id;  
    if (resp.multi_logins_allowed) {
      this.sessionId = 'multi_logins_allowed';         
    } else {
      this.sessionId = resp.session_id;
    }
    console.log(product_name + " " + this.sessionId + " " + uuId);
    if (product_name === 'RC2') {
      this.rc_encryptPassword(pass, username, verificationKey, amaLoginResponse);
    } else if (product_name === 'RCClaimAssist') {
      let url = this.configuration.RCCAUrl + '/?sid=' + this.sessionId + '&uid=' + uuId;
      window.location.replace(url);
    } else if (product_name === 'RCCALite') {
      let url = this.configuration.RCCALiteUrl + '/?sid=' + this.sessionId + '&uid=' + uuId;
      window.location.replace(url);
    } else {
      this.router.navigate(['/layout']);
    }
  }

  rc_encryptPassword(pass: string, username: string, verificationKey: string, amaLoginResponse: object) {
    console.log("rc_encryptPassword");
    var userName = username.toLowerCase();
    sessionStorage["userName"] = userName;
    sessionStorage["pass"] = pass;
    var userPass = pass;
    this.rc_doLogin(amaLoginResponse, username, verificationKey);
  }

  rc_doLogin(resp: object, username: string, verificationKey: string) {
    console.log("rc_doLogin ");
    if (resp === null || resp === []) {
      window.name = '';
      window.name = '{"msg": "Username / Password is incorrect.", "statusFlg" : "success"}';
      window.scrollTo(0, 0);
      window.location.replace(this.configuration.Rc3Url + "/login-error/");

    }
    else {
      const getUserDetails_function_name = '/account';
      const getUserDetails_url = this.configuration.ServiceRootUrl + getUserDetails_function_name;

      let headers = this.setHeaders();
      return this._http.post(getUserDetails_url, {}, { headers }).subscribe(res => {
        this.getUserDetails_accountIdFunc(res, resp, verificationKey);
      });
    }
  }

  getUserDetails_accountIdFunc(accounIDResponse, resp, verificationKey: string) {
    console.log("getUserDetails_accountIdFunc")
    if (accounIDResponse.status === 503) {
      window.scrollTo(0, 0);
      window.location.replace(this.configuration.Rc3Url + "/service-error-response/");
    } else {
      var parsedJson = accounIDResponse[0];
      sessionStorage.setItem("firstname", parsedJson['contact_first']);
      sessionStorage.setItem("lastname", parsedJson['contact_last']);

      var response_arr = resp[0];
      let ret_response = [];
      const numberOfElements = Object.keys(response_arr).length;
      var product_name;

      if (numberOfElements) {
        ret_response = response_arr;
        product_name = response_arr.website_product_name;
      }
      var termination_date = moment(ret_response['termination_date']);
      var currentdate = moment(ret_response['current_date_time']);
      if (currentdate <= termination_date) {
        if (ret_response.hasOwnProperty('aa_id') && ret_response['aa_id']) {
          if (ret_response['aa_id'] === verificationKey) {
            this.termAAIDAndRedirectToRespectiveApp(ret_response, product_name);
          }
        } else {
          this.encodedAccountId(ret_response, product_name);
        }
      } else {
        window.name = '';
        window.name = '{"msg": "Your account has been terminated", "statusFlg" : "success"}';
        window.scrollTo(0, 0);
        window.location.replace(this.configuration.Rc3Url + "/login-error/");
      }
    }
  }

  encodedAccountId(ret_resp, product_name: string) {
    console.log("encodedAccountId");
    var encryptUserAccountID = ret_resp.account_id;
    var currentDate = new Date();
    var dateAccounID = currentDate + '/' + encryptUserAccountID
    const functionName = '/rest/Coding/enmessage';
    let userDetails = { 'key': dateAccounID };

    const urlEecryptingAccountID = this.configuration.CryptoWebService + functionName;

    return this._http.post(urlEecryptingAccountID, userDetails, { responseType: 'text' }).subscribe(res => {
      this.checkSessionAndRedirectToRespectiveApplication(ret_resp, product_name, res);
    });

  }

  checkSessionAndRedirectToRespectiveApplication(resp, product_name, encodedUserAccountId) {
    console.log("checkSessionAndRedirectToRespectiveApplication");
    if (resp.multi_logins_allowed) {

      sessionStorage.clear();
      sessionStorage.removeItem('encKey');
      // this.router.navigate(['/layout']);
      // location.replace(this.configuration.RcNdcXwalkUrl);
      window.name = '{"multi_logins_allowed" : "' + resp.multi_logins_allowed + '"}';
      this.sessionId = 'multi_logins_allowed';
      var uuId = resp.session_id;
      window.scrollTo(0, 0);
      // let url = this.configuration.Rc3Url + '/ama/?prods=' + product_name + '&key1=' + encodedUserAccountId + '&sid=' + this.sessionId + '&uid=' + uuId;
      /*  let url = this.configuration.Rc3Url + '/?key=' + encodedUserAccountId + '&sid=' + this.sessionId + '&uid=' + uuId;
       console.log(url);
       window.location.replace(url); */
    } else {
      window.name = '{"session_id" : "' + resp.session_id + '"}';
      this.sessionId = resp.session_id;
      var uuId = resp.session_id;

      sessionStorage.clear();
      sessionStorage.removeItem('encKey');
      //location.replace(this.configuration.RcNdcXwalkUrl);
      // this.router.navigate(['/layout']);
      window.scrollTo(0, 0);
      // let url = this.configuration.Rc3Url + '/ama/?prods=' + product_name + '&key1=' + encodedUserAccountId + '&sid=' + uuId + '&uid=' + uuId;
      /* let url = this.configuration.Rc3Url + '/?key=' + encodedUserAccountId + '&sid=' + this.sessionId + '&uid=' + uuId;
      console.log(url)
      window.location.replace(url); */
    }

    if (product_name === 'RC2') {
      let url = this.configuration.Rc3Url + '/?key=' + encodedUserAccountId + '&sid=' + this.sessionId + '&uid=' + uuId;
      console.log(url)
      window.location.replace(url);
    } else if (product_name === 'RCClaimAssist') {
      let url = this.configuration.RCCAUrl + '/?sid=' + this.sessionId + '&uid=' + uuId;
      console.log(url)
      window.location.replace(url);
    } else if (product_name === 'RCCALite') {
      let url = this.configuration.RCCALiteUrl + '/?sid=' + this.sessionId + '&uid=' + uuId;
      console.log(url)
      window.location.replace(url);
    } else {
      console.log(this.router.url)
      this.router.navigate(['/layout']);
    }
  }

  termAAIDAndRedirectToRespectiveApp(amaLoginResponse, product_name: string) {
    console.log("termAAIDAndRedirectToRespectiveApp");
    var data_key = '{"aa_id":"' + amaLoginResponse['aa_id'] + '"}';
    let userDetails = { 'aa_id': amaLoginResponse['aa_id'] };

    // CALL TO API FOR CHECKING DATE TIME
    const function_name = '/account/account_verif_confirm';
    const url_verify = this.configuration.ServiceRootUrl + function_name;

    var verifyFunc = this.encodedAccountId(amaLoginResponse, product_name);
    let headers = this.setHeaders();
    return this._http.post(url_verify, userDetails, { headers }).subscribe(res => {
      verifyFunc;
    });
  }

  // Registration Services
  npi_lookup(npi: string) {
    let headers = this.setHeaders();
    return this._http.get(this.configuration.ServiceRootUrl + '/lookupnpi/' + npi, { headers, observe: 'response' })
  }

  npiOrg_lookup(npi: string) {
    let headers = this.setHeaders();
    return this._http.get(this.configuration.ServiceRootUrl + '/lookupnpi/' + npi + '/' + this.configuration.FreeTrialOrg, { headers, observe: 'response' })
  }

  username_lookup(username: string) {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceRootUrl + '/amalogin/lookup', { 'username': username }, { headers, observe: 'response' })
  }

  registerUser(jsonData: object) {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/account/addAccount', jsonData, { headers });
  }

  //Forgot Password services
  initiateForgotPassword(jsonData: object) {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/forgotPassword', jsonData, { headers })
  }

  //Validation Services
  public validateOrg(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/email/validateOrg', jsonData, { headers });
  }

  public validateEmail(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/email/validateEmail', jsonData, { headers });
  }

  public validateUsername(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/email/validateUsername', jsonData, { headers });
  }

  public validatePassword(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/email/validatePassword', jsonData, { headers });
  }
  
  public sendVerificationEmail(jsonData: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/email/sendVerification', jsonData, { headers });
  }
}
