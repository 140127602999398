import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { EnvironmentData } from '../../shared/models/environment.data.model';
import { GlobalSearchViews } from '../../shared/models/global-search-views.data.model';
import { CheckSessionOutput } from '../../shared/models/checksession-output.data.model';
@Injectable({
  providedIn: 'root',
})
export class HcpcSearchService {
  public headers: HttpHeaders = null;
  public config: EnvironmentData;
  constructor(private http: HttpClient) { }

  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
  }

  public getJcodeResponse(accountId: number, inputHcpcCode: string): Observable<GlobalSearchViews> {
    const headers = this.setHeaders();
    return this.http.get(this.config.ServiceUrl + '/gsv/' + 'I' + '/' + '0' + '/' + '0/' + '1000/' + inputHcpcCode
      , { headers: headers });
  }

}
