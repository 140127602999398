import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { NavService } from '../../shared/services/commonServices';
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss'],
})
export class SuccessComponent implements OnInit {
  public isLoadingFromHcpc : string = '';
  public isLoadingFromNdc : string = '';
  public selectedNdcCode:string;
  public selectedNdcCodeDesc:string;
  public selectedHcpcCodefromHcpc:string;
  public selectedHcpcDescrfromHcpc:string;
  public UserTypedValueOnNdc : string = null;
  public UsertypedValueOnHcpc:string='';
  public selectedNdcCodeDescr : string = null;
  public selectedHcpcCodeOnHcpcSearch:string ;
  public isFromNdc:boolean=false;
  public rcId : string = null;
  public reImbCode : string = null;
  public rcdescr : string = null;
  public selectedHcpcDescrOnHcpcSearch:string ;
  public isLoadingFromNdcForDrug : string;
  public userTypedValueOnDrugNameSearch : string;
  public ndcStatus : string = null;
  public hcpcStatus : string = null;
  public termDate : string =null;
  public screenWidth: number;
  public openPopover: string;

  constructor(
    private _router: Router,
    private _route:ActivatedRoute,
    private actvatedRoute: ActivatedRoute,
    private router:Router,
    private navsvc : NavService)  { }

  public ngOnInit():void {
    this.isLoadingFromNdc = this.actvatedRoute.snapshot.queryParamMap.get('isFromNdc');
    this.isLoadingFromHcpc= this.actvatedRoute.snapshot.queryParamMap.get('isFromHCpc');
    this.reImbCode = this.actvatedRoute.snapshot.queryParamMap.get('hcpccode');
    this.rcdescr = this.actvatedRoute.snapshot.queryParamMap.get('hcpcdescr');
    this.selectedNdcCode = this.actvatedRoute.snapshot.queryParamMap.get('ndccode');
    this.selectedNdcCodeDesc = this.actvatedRoute.snapshot.queryParamMap.get('ndcdescr');
    this.selectedHcpcCodefromHcpc = this.actvatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromHcpc');
    this.selectedHcpcDescrfromHcpc = this.actvatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromHcpc');
    this.UserTypedValueOnNdc = this.actvatedRoute.snapshot.queryParamMap.get('usertypedValueonNdc');
    this.UsertypedValueOnHcpc = this.actvatedRoute.snapshot.queryParamMap.get('usertypedValueOnHcpc');
    this.rcId = this.actvatedRoute.snapshot.queryParamMap.get('rcId');
    this.selectedNdcCodeDescr=this.actvatedRoute.snapshot.queryParamMap.get('selectedNdcCodeDescr');
    
    this.selectedHcpcCodeOnHcpcSearch = this.actvatedRoute.snapshot.queryParamMap.get('hcpccode');
    this.selectedHcpcDescrOnHcpcSearch = this.actvatedRoute.snapshot.queryParamMap.get('hcpcdescr');
    this.isLoadingFromNdcForDrug = this.actvatedRoute.snapshot.queryParamMap.get('isFromNdcForDrug');
    this.ndcStatus = this.actvatedRoute.snapshot.queryParamMap.get('status');
    this.hcpcStatus = this.actvatedRoute.snapshot.queryParamMap.get('hcpcStatus');
    this.termDate = this.actvatedRoute.snapshot.queryParamMap.get('termDate');
    this.screenWidth = window.innerWidth;
    if (this.screenWidth < 1199) {
      this.openPopover = 'click';
    }
    else {
      this.openPopover = 'mouseenter:mouseleave, click';
    }
    if(this.isLoadingFromNdc === 'true')
    {
      this.isFromNdc=true;
    }
    else if(this.isLoadingFromHcpc === 'true'){
      this.selectedNdcCode=this.actvatedRoute.snapshot.queryParamMap.get('selectedNdcCode');
    }else if (this.isLoadingFromNdcForDrug === 'true'){
      this.userTypedValueOnDrugNameSearch =  this.actvatedRoute.snapshot.queryParamMap.get('userTypedValueOnDrugNameSearch');
    }
    this.selectedNavItem(0);
}
  public redirectToNdc():void{
    let navigationExtras:NavigationExtras={
      queryParams:{
        UsertypedValueOnHcpc:this.UsertypedValueOnHcpc,
        UserTypedValueOnNdc:this.UserTypedValueOnNdc,
        selectedHcpcCodefromHcpc:this.selectedHcpcCodefromHcpc,
        selectedHcpcDescrfromHcpc:this.selectedHcpcDescrfromHcpc,
        rcId : this.rcId,
        hcpcStatus:this.hcpcStatus,
        isFromSuccess:'true',
      },
    };
    this.router.navigate(['/layout/ndcsearch'],navigationExtras);
  }

  public redirectToHcpc():void{
    let navigationExtras:NavigationExtras={
      queryParams:{
        reImbCode:this.reImbCode,
        rcdescr:this.rcdescr,
        ndccode:this.selectedNdcCode,
        ndcdescr:this.selectedNdcCodeDescr,
        isFromSuccess:'true',
        status : this.ndcStatus,
        hcpcStatus:this.hcpcStatus,
        userTypedValue:this.UserTypedValueOnNdc,
      },
    };
    this.router.navigate(['/layout/hcpcsearch'],navigationExtras);
  }

  public redirectToNdcForDrug():void{
    let navigationExtras:NavigationExtras={
      queryParams:{
        selectedHcpcCodefromDrug:this.selectedHcpcCodefromHcpc,
        selectedHcpcDescrfromDrug:this.selectedHcpcDescrfromHcpc,
        ndccode:this.selectedNdcCode,
        ndcdescr:this.selectedNdcCodeDescr,
        isFromSuccessForDrug:'true',
        rcId : this.rcId,
        userTypedValueOnDrugNameSearchfromSucess:this.userTypedValueOnDrugNameSearch,
        hcpcStatus:this.hcpcStatus,
        status : this.ndcStatus,
      },
    };
    this.router.navigate(['/layout/ndcsearch'],navigationExtras);
  }

  public selectedNavItem(item: number):void {
  //  console.log('selected nav item ' + item);
    this.navsvc.changeNav(item);
  }

}
