import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  public error: boolean = false;
  public errMsg: string = '';
  public isValid: boolean = false;
  public email: string = '';
  public redirectURL: string = '';

  PasswordForm = this.fb.group({
    userEmail: [null, Validators.required]
  })

  constructor(
    private loginService: LoginService,
    private router: Router,
    private fb: FormBuilder
  ) { }

  ngOnInit() { }

  onSubmit() {
    this.isValid = true;
    this.error = false;
    this.email = this.PasswordForm.controls.userEmail.value;
    if (this.email === null || this.email === undefined || this.email.length === 0) {
      this.error = true;
      this.errMsg = 'Email or username is required.';
      this.isValid = false;
    }

    if (this.isValid) {
      this.loginService.username_lookup(this.email).subscribe(res => {
        console.log(res.body)
        if (res.status === 200) {
          this.redirectURL = res.body[0]['sso_redirect_url'];
          this.email = res.body[0]['email'];
          if (this.redirectURL) {          
                window.location.replace(this.redirectURL);
          } else {
            this.forgotPassword();
          }
        } else if (res.status === 204) {
          this.error = true;
          this.errMsg = 'Invalid username/email';
        }
      }, () => {
        this.error = true;
        this.errMsg = 'Unknown error';
      });
    }
  }

  forgotPassword() {
    if (this.redirectURL) {
      window.location.replace(this.redirectURL);
    }
    if (this.email === null || this.email === undefined || this.email.length === 0) {
      this.error = true;
      this.errMsg = 'No email found for user';
    }

    let jsonData =
    {
      'email': this.email,
      'site': 'RCNDCXwalk'
    };

    this.loginService.initiateForgotPassword(jsonData).subscribe(res => {
      console.log("Forgot password service");
      if (res['success'] === 'true' || res['success'] === true) {
        console.log("Successful");
        window.scrollTo(0, 0);
        alert('An email has been sent to you with your username and password.');
        this.router.navigate(['/login']);
      } else if (res['success'] === 'false' || res['success'] === false) {
        this.error = true;
        this.errMsg = 'Invalid email';
      } else {
        this.error = true;
        this.errMsg = 'Unknown error';
      }
    }, err => {
      this.error = true;
      this.errMsg = 'Unknown error';
    });
  }
}
