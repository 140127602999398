import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject, Subscription, timer } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { CheckIdleTimeService } from '../../shared/services/check-idle-time.service';
import { NavService } from '../../shared/services/commonServices';
import { GlobalServiceService } from '../../shared/services/globalServices';
import { HeaderService } from '../layout/header.service';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {

  public accountId: number;
  public userName: string;
  public sessionInterval: number;
  public minutesDisplay: number = 0;
  public secondsDisplay: number = 0;

  public endTime: number = JSON.parse(sessionStorage['config']).sessionExpiryDuration;

  public unsubscribe$: Subject<void> = new Subject();
  public timerSubscription: Subscription;
  public display: string = 'none';
  public url: string;
  constructor(
    private headerService: HeaderService,
    private globalwindowService: GlobalServiceService,
    private modalService: NgbModal,
    private navsvc: NavService,
    private authService: AuthorizationServices,
    private checkIdleTimeSvc: CheckIdleTimeService,
    private router: Router
    ) { }

  public ngOnInit(): void {
    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.userName = JSON.parse(sessionStorage.getItem('accountDetails')).contact_first;
    this.resetTimer();
    this.checkIdleTimeSvc.userActionOccured.pipe(
      takeUntil(this.unsubscribe$)
    ).subscribe(() => {
      if (this.timerSubscription) {
        this.timerSubscription.unsubscribe();
      }
      this.resetTimer();
    });
    //this.isSessionActive();
  }

  public OnDestroy(): void {
    if (this.sessionInterval) {
      clearInterval(this.sessionInterval);
    }
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  public onMenuChange(): void {
    //$('#ember610').removeClass('active');
  }

  public selectedNavItem(item: number): void {
    this.navsvc.changeNav(item);
  }

  public openModal(): void {
    this.display = 'block';
  }
  public onCloseHandled(): void {
    this.display = 'none';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    location.replace(JSON.parse(sessionStorage['config']).amaApplicationUrl);
    sessionStorage.removeItem('config');
  }

  public setFooterContact(): void {
    this.selectedNavItem(5);
  }

  public setFooterFaq(): void {
    this.selectedNavItem(4);
  }

  public setFooterToDefault(): void {
    this.selectedNavItem(6);
  }

  public logOut(): void {
    localStorage.removeItem('key');
    let sessionId = sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : '';
    if (sessionId) {
      this.authService.deleteSession(sessionId).subscribe(res => {
        sessionStorage.clear();
        this.router.navigate(['/']);
        /* window.name = '';
        this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
        sessionStorage.clear();
        location.replace(this.url); */
      }, (err: Response) => {
        sessionStorage.clear();
        this.router.navigate(['/']);
        /* this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
        sessionStorage.clear();
        location.replace(this.url); */
        });
    } else {
      sessionStorage.clear();
      this.router.navigate(['/']);
      /* this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
      sessionStorage.clear();
      location.replace(this.url); */
    }

  }

  public clearSession(): void {
    window.name = '';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    sessionStorage.removeItem('config');
    location.replace(this.url);
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }

  public resetTimer(endTime: number = this.endTime): void {
    const interval = 1000;
    const duration = endTime * 60;
    this.timerSubscription = timer(0, interval).pipe(
      take(duration)
    ).subscribe(value =>
      this.render((duration - +value) * interval),
      err => {//
      },
      () => {
        this.checkIdleTimeSvc.logOutUser();
      }
    );
  }

  private render(count: number): void {
    this.secondsDisplay = parseInt(this.getSeconds(count));
    this.minutesDisplay = parseInt(this.getMinutes(count));
  }

  private getSeconds(ticks: number): string {
    const seconds = ((ticks % 60000) / 1000).toFixed(0);
    return this.pad(parseInt(seconds));
  }

  private getMinutes(ticks: number): string {
    const minutes = Math.floor(ticks / 60000);
    return this.pad(minutes);
  }

  private pad(digit: number): string {
    return digit <= 9 ? '0' + digit : digit.toString();
  }
}
