import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutusComponent } from './main/about/aboutus.component';
import { ContactComponent } from './main/contact/contact.component';
import { DrugNameSearchComponent } from './main/drug-name-search/drug-name-search.component';
import { FaqComponent } from './main/faq/faq.component';
import { HcpcSearchComponent } from './main/hcpc-search/hcpc-search.component';
import { LayoutComponent } from './main/layout/layout.component';
import { SessionTimeOutComponent } from './main/layout/sessiontimeout.component';
import { NdcSearchComponent } from './main/ndc-search/ndc-search.component';
import { PasswordComponent } from './main/password/password.component';
import { SearchComponent } from './main/search/search.component';
import { SuccessComponent } from './main/success/success.component';
import { AccessDeniedComponent } from './main/unauth/access-denied/access-denied.component';
import { ForgotPasswordComponent } from './main/unauth/forgot-password/forgot-password.component';
import { InvalidSignatureComponent } from './main/unauth/invalid-signature/invalid-signature.component';
import { LoginComponent } from './main/unauth/login/login.component';
import { NpiValidateComponent } from './main/unauth/npi-validate/npi-validate.component';
import { PrivacyPolicyComponent } from './main/unauth/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './main/unauth/register/register.component';
import { TermsAndConditionsComponent } from './main/unauth/terms-and-conditions/terms-and-conditions.component';
import { UnauthAboutComponent } from './main/unauth/unauth-about/unauth-about.component';
import { UnauthContactComponent } from './main/unauth/unauth-contact/unauth-contact.component';
import { UnauthFaqComponent } from './main/unauth/unauth-faq/unauth-faq.component';
import { UnauthComponent } from './main/unauth/unauth.component';
import { VerificationPageComponent } from './main/unauth/verification-page/verification-page.component';
import { AuthGuard } from './shared/guards/auth.guard';

const routes: Routes = [
  {
    path: '',
    component: UnauthComponent,
    children: [
      { path: 'about', component: UnauthAboutComponent },
      { path: 'contactus', component: UnauthContactComponent },
      { path: 'faqs', component: UnauthFaqComponent },
      { path: 'login', component: LoginComponent },
      { path: 'register', component: NpiValidateComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
      { path: 'reg-form', component: RegisterComponent },
      { path: 'tandc', component: TermsAndConditionsComponent },
      { path: 'privacy-policy', component: PrivacyPolicyComponent },
      { path: 'verification-page', component: VerificationPageComponent },
      { path: 'access-denied-rcndcxwalk', component: AccessDeniedComponent},
      { path: 'invalid-signature-rcndcxwalk', component: InvalidSignatureComponent},
      { path: '', component: UnauthAboutComponent }
    ],
  },
  {
    path: 'layout',
    component: LayoutComponent, canActivate: [AuthGuard],
    children: [{
      path: '',
      component: SearchComponent,
    },
    {
      path: 'aboutus',
      component: AboutusComponent,
    },
    {
      path: 'faq',
      component: FaqComponent,
    },
    {
      path: 'contact',
      component: ContactComponent,
    },
    {
      path: 'search',
      component: SearchComponent,
    },
    {
      path: 'success',
      component: SuccessComponent,
    },
    {
      path: 'hcpcsearch',
      component: HcpcSearchComponent,
    },
    {
      path: 'ndcsearch',
      component: NdcSearchComponent,
    },
    {
      path: 'drugnamesearch',
      component: DrugNameSearchComponent,
    },
    {
      path: 'sessiontimeout',
      component: SessionTimeOutComponent,
    },
    {
      path: 'password',
      component: PasswordComponent,
    },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [{ provide: LocationStrategy, useClass: HashLocationStrategy }],
})
export class AppRoutingModule { }
