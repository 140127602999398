import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CommonService } from 'src/app/shared/services/common.service';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { NavService } from '../../shared/services/commonServices';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
})
export class SearchComponent implements OnInit {
  public showCloseButton: boolean = true;
  @ViewChild('resetPasswordPopup', { static: true }) public resetPasswordPopup: string;

  constructor(private _router: Router,private _route:ActivatedRoute,private navsvc : NavService,
    private modalService: NgbModal,
		private authServices: AuthorizationServices,
		private commonService: CommonService
  ) { }

  public ngOnInit():void {
    var firstTimeLogin = sessionStorage.getItem('firstTimeLogin');
    if (firstTimeLogin === null) {
      sessionStorage.setItem('firstTimeLogin', 'false');
      var browserDetails = this.commonService.getBrowserDetails();
      var criteria = browserDetails.OS_Name + ', ' + browserDetails.Browser_Name + ', ' + browserDetails.Full_Version;
      var unilogTrack = {
          //'account_id':this.accountDetails.account_id,
          'service_used': 'UNILOG',
          'search_criteria': criteria,
          'r_id':'',
      };
      this.commonService.addHistory(unilogTrack);
    }
    this.selectedNavItem(0);
    var accountActivityRequest = {
      'account_id': JSON.parse(sessionStorage.getItem('accountDetails')).account_id,
      'aa_type': 'FLD',
    };
    this.authServices.accountActivities(accountActivityRequest).subscribe(res => {
        
        for (var i = 0; i < res['length']; i++) {
            let aaCode = res[i].aa_code;
            var newVal = res[i].new_val;
            if ( aaCode === 'PWDR' && newVal.length > 0) {
                let date: Date = new Date(newVal);
                let currentDate = new Date();
                currentDate.setHours(0,0,0,0);
                if (date.getTime() < (currentDate).getTime()) {
                    this.showCloseButton = false;
                }
                this.openPasswordResetPopup();
                break;
            }
        }
    });
  }

  public selectedNavItem(item: number):void {
    //console.log('selected nav item ' + item);
    this.navsvc.changeNav(item);
  }
  
  public openPasswordResetPopup(): void {
		if (sessionStorage.getItem('showPwdResetModal') !== 'false') {
			this.modalService.open(this.resetPasswordPopup, { windowClass: 'welcomeRCCA', centered: true, backdrop: 'static', keyboard: false, size: 'md' });
		}
  }

  public openResetPage(): void {
    this.modalService.dismissAll();
    this._router.navigate(['/layout/password']);
	}
	
	public hidePwdResetModal(): void {
		sessionStorage.setItem('showPwdResetModal', 'false');
	}
}
