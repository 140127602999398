import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxCaptchaModule } from 'ngx-captcha';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { NgxMaskModule } from 'ngx-mask';
import { CommonService } from 'src/app/shared/services/common.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutusComponent } from './main/about/aboutus.component';
import { ContactComponent } from './main/contact/contact.component';
import { DrugNameSearchComponent } from './main/drug-name-search/drug-name-search.component';
import { FaqComponent } from './main/faq/faq.component';
import { HcpcSearchComponent } from './main/hcpc-search/hcpc-search.component';
import { FooterComponent } from './main/layout/footer.component';
import { HeaderComponent } from './main/layout/header.component';
import { LayoutComponent } from './main/layout/layout.component';
import { SessionTimeOutComponent } from './main/layout/sessiontimeout.component';
import { NdcSearchComponent } from './main/ndc-search/ndc-search.component';
import { PasswordComponent } from './main/password/password.component';
import { SearchComponent } from './main/search/search.component';
import { SuccessComponent } from './main/success/success.component';
import { ForgotPasswordComponent } from './main/unauth/forgot-password/forgot-password.component';
import { LoginComponent } from './main/unauth/login/login.component';
import { NpiValidateComponent } from './main/unauth/npi-validate/npi-validate.component';
import { PrivacyPolicyComponent } from './main/unauth/privacy-policy/privacy-policy.component';
import { RegisterComponent } from './main/unauth/register/register.component';
import { TermsAndConditionsComponent } from './main/unauth/terms-and-conditions/terms-and-conditions.component';
import { UnauthAboutComponent } from './main/unauth/unauth-about/unauth-about.component';
import { UnauthContactComponent } from './main/unauth/unauth-contact/unauth-contact.component';
import { UnauthFaqComponent } from './main/unauth/unauth-faq/unauth-faq.component';
import { UnauthFooterComponent } from './main/unauth/unauth-footer/unauth-footer.component';
import { UnauthHeaderComponent } from './main/unauth/unauth-header/unauth-header.component';
import { UnauthComponent } from './main/unauth/unauth.component';
import { ToastContainerComponent } from './shared/component/toast-container/toast-container.component';
import { GlobalHttpInterceptorService } from './shared/interceptor/globalHttpInterceptorService';
import { PagerService } from './shared/pagination/pager.service';
import { NavService } from './shared/services/commonServices';
import { GlobalServiceService } from './shared/services/globalServices';
import { Logger } from './shared/services/logger.service';
import { VerificationPageComponent } from './main/unauth/verification-page/verification-page.component';
import { AccessDeniedComponent } from './main/unauth/access-denied/access-denied.component';
import { InvalidSignatureComponent } from './main/unauth/invalid-signature/invalid-signature.component';

@NgModule({
  declarations: [
    AppComponent,
    LayoutComponent,
    AboutusComponent,
    HeaderComponent,
    FooterComponent,
    FaqComponent,
    ContactComponent,
    SearchComponent,
    HcpcSearchComponent,
    NdcSearchComponent,
    UnauthComponent,
    SuccessComponent,
    DrugNameSearchComponent,
    SessionTimeOutComponent,
    ToastContainerComponent,
    PasswordComponent,
    UnauthAboutComponent,
    UnauthHeaderComponent,
    UnauthFooterComponent,
    UnauthFaqComponent,
    UnauthContactComponent,
    LoginComponent,
    RegisterComponent,
    NpiValidateComponent,
    PrivacyPolicyComponent,
    TermsAndConditionsComponent,
    ForgotPasswordComponent,
    VerificationPageComponent,
    AccessDeniedComponent,
    InvalidSignatureComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    DeviceDetectorModule.forRoot(),
    NgxMaskModule.forRoot(),
    MatSnackBarModule,
    BrowserAnimationsModule
  ],
  providers: [
    PagerService,
    GlobalServiceService,
    NavService,
    Logger,
    CommonService,
    { provide: HTTP_INTERCEPTORS, useClass: GlobalHttpInterceptorService, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
