import { Component, HostListener } from '@angular/core';
import { CheckIdleTimeService } from './shared/services/check-idle-time.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  public isFromAMA: boolean = false;

  constructor(private checkUserActionService: CheckIdleTimeService) { }

  @HostListener('document:keyup')
  @HostListener('document:click')
  @HostListener('document:wheel')
  @HostListener('document:mousemove')
  @HostListener('window:focus')
  public resetTimer(): void {
    this.checkUserActionService.notifyUserAction();
  }

  public OnInit(): void {
    this.isFromAMA = true;
    //Verify and change amaApplicationUrl
  }

}
