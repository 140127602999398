import { Component, OnInit } from '@angular/core';
import { LoginService } from 'src/app/shared/services/login.service';

@Component({
  selector: 'app-verification-page',
  templateUrl: './verification-page.component.html'
})
export class VerificationPageComponent implements OnInit {

  public emailDetails = {};

  constructor(private loginService: LoginService) { }

  ngOnInit() {
    this.emailDetails = JSON.parse(sessionStorage.getItem('verificationEmail'));
  }

  sendEmail() {
    this.loginService.sendVerificationEmail(this.emailDetails).subscribe(data => {
      let status = data['status'];
          if (status === 0 || status === '0') {
            window.scrollTo(0, 0);
            alert('Email sent to your account successfully.');
          } else {
            alert('Error in sending email');
          }
        }, () => {
          alert('Error in sending email');
        });
  }


}
