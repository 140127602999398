import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { AuthorizationServices } from '../services/authorizationServices';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        private authenticationService: AuthorizationServices
    ) { }

    public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        //const currentUser = this.authenticationService.currentUserValue;
        const currentUser = sessionStorage.getItem('isValidUser');
        if (currentUser) {
            // authorised so return true
            let sessionId = sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : '';
            this.authenticationService.checkSession(sessionId).subscribe(res => {
                if (res)
                    return true;
                else {
                    let config = sessionStorage.getItem('config');
                    sessionStorage.clear();
                    sessionStorage.setItem('config', JSON.stringify(config));

                    return false;
                }
            })
            return true;
        } else {
            let config = sessionStorage.getItem('config');
            sessionStorage.clear();
            sessionStorage.setItem('config', JSON.stringify(config));
        }

        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login']);
        return false;
    }
}
