import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserModel } from 'src/app/shared/models/UserModel';
import { environment as env } from 'src/environments/environment';
import { AccountOutput } from '../models/account-output.data.model';
import { CheckSessionOutput } from '../models/checksession-output.data.model';
import { EnvironmentData } from '../models/environment.data.model';

@Injectable({
  providedIn: 'root',
})

export class AuthorizationServices {

  private currentUserSubject: BehaviorSubject<UserModel>;
  public currentUser: Observable<UserModel>;
  public headers: HttpHeaders = null;
  public configuration: EnvironmentData = JSON.parse(sessionStorage.getItem('config'));

  constructor(private _http: HttpClient) {
    this.currentUserSubject = new BehaviorSubject<UserModel>(JSON.parse(localStorage.getItem('currentUser')));
    this.currentUser = this.currentUserSubject.asObservable();

  }

  private setHeaders(): HttpHeaders {
    this.configuration = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.configuration.Authorization);
  }

  public get currentUserValue(): UserModel {
    return this.currentUserSubject.value;
  }

  public getConfig(): Observable<EnvironmentData> {
    return this._http.get('./assets/config/config.json') as Observable<EnvironmentData>;
  }

  public getAmaLoginDetails(jsonData: object): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/amalogin', jsonData, { headers });
  }

  public logout(): void {
    // remove user from local storage to log user out
    localStorage.removeItem('currentUser');
    this.currentUserSubject.next(null);
  }

  public getAccountDetails(accountId: string): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/account', { 'accountid': accountId }, { headers });
  }
  public decryptMessage(jsonData: object): Observable<string> {
    this.setHeaders();
    return this._http.post(this.configuration.CryptoWebService + '/rest/Coding/demessage', jsonData, { responseType: 'text' });
  }
  public deleteSession(sessionId: string): Observable<AccountOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/deleteSession', { 'session_id': sessionId }, { headers });
  }
  public checkSession(sessionId: string): Observable<CheckSessionOutput> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/checkSession', { 'session_id': sessionId }, { headers });
  }
  public accountActivities(accountActivityRequest: object): Observable<object> {
    let headers = this.setHeaders();
    return this._http.post(this.configuration.ServiceUrl + '/account_activities', accountActivityRequest, { headers });
  }
}
