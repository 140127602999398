import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { GlobalSearchViews } from '../../shared/models/global-search-views.data.model';
import { PagerService } from '../../shared/pagination/pager.service';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { NavService } from '../../shared/services/commonServices';
import { HeaderService } from '../layout/header.service';
import { DrugNameSearchService } from './drug-name-search.service';
import { debounceTime } from 'rxjs/operators';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-drug-name-search',
  templateUrl: './drug-name-search.component.html',
  styleUrls: ['./drug-name-search.component.scss'],
})
export class DrugNameSearchComponent implements OnInit {

  public usertypedValueOnDrugNameSearch: string;
  public model: { 'drugCodes': string } = { 'drugCodes': null };
  public showErrorMesage: boolean = false;
  public drugCodes: GlobalSearchViews[] = [];
  public errorMessage: string;
  public pagedItems: GlobalSearchViews[] = [];
  // tslint:disable-next-line: no-any
  public pager: any = {};
  public pageindex: number;
  public selectedDrugCodesDescr: string;
  public selectedDrugCodes: string;
  public hcpcsCodes: GlobalSearchViews[] = [];
  public isResultFound: boolean = false;
  public showData: boolean = false;
  public searchTextChanged: Subject<string> = new Subject<string>();
  public display: string = 'none';
  public drugCode: string = '';
  public accountId: number;
  public isFirstTimeLoad: boolean = true;
  public isFromNdcOnPreviousClickThroughSuccess: string;
  public isFromNdcOnPreviousClick: string;
  public selectedHcpcCodeOnHcpcSearch: string;
  public selectedHcpcDescrOnHcpcSearch: string;
  public usertypedValueOnHcpc: string;
  private url: string;

  constructor(
    private data: DrugNameSearchService,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private headerService: HeaderService,
    private authService: AuthorizationServices,
    private navsvc: NavService) { }

  public ngOnInit(): void {
    //$('#ember610').addClass('active');
    this.model.drugCodes = '';
    this.isResultFound = false;
    this.usertypedValueOnDrugNameSearch = '';
    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.showData = false;
    this.isFromNdcOnPreviousClick = this.activatedRoute.snapshot.queryParamMap.get('isFromNdcOnPreviousClick');
    this.isFromNdcOnPreviousClickThroughSuccess = this.activatedRoute.snapshot.queryParamMap.get('isFromNdcOnPreviousClickThroughSuccess');
    if (this.isFromNdcOnPreviousClickThroughSuccess) {
      this.isFirstTimeLoad = false;
      this.model.drugCodes = this.activatedRoute.snapshot.queryParamMap.get('userTypedValue');
      this.fetchDataForDrugName(this.model.drugCodes);
    }
    else if (this.isFromNdcOnPreviousClick) {
      this.isFirstTimeLoad = false;
      this.model.drugCodes = this.activatedRoute.snapshot.queryParamMap.get('userTypedValueOnDrugNameSearchFromNdc');
      this.fetchDataForDrugName(this.model.drugCodes);
    }
    this.isSessionActive();
    this.searchTextChanged.pipe(debounceTime(1000))
      .subscribe((drugCode) => this.fetchDataForDrugName(drugCode));
  }

  public callOnKeyUp(e: Event): void {
    if ((e['which'] < 48 || e['which'] > 105) && e['which'] !== 8 && e['which'] !== 16) {
      return;
    }
    if (e['which'] === 91 || e['which'] === 92 || e['which'] === 93) {
      return;
    }
    this.pagedItems = [];
    this.pager = {};
    this.drugCodes = [];
    this.isResultFound = false;
    this.showData = false;
    this.selectedNavItem(3);
    if (this.model.drugCodes.length >= 3) {
      let temp = e.target['value'].toString().replace('/', '%2F')
      this.searchTextChanged.next(temp);
    }
    else {
      return;
    }
  }

  public fetchDataForDrugName(drugCode: string): void {
    console.log("fetchDataForDrugName");
    this.usertypedValueOnDrugNameSearch = this.model.drugCodes;
    this.data.getDrugName(this.accountId, drugCode).subscribe(data => {
      if (data === null) {
        this.isResultFound = true;
        return;
      }
      this.filterUnwantedData(Object.assign([], data));

    },
      error => {
        this.isResultFound = true;
      });

  }

  public selectedNavItem(item: number): void {
    this.navsvc.changeNav(item);

  }

  public clearModel(): void {
    setTimeout((): void => { $('.cancel-icon').toggleClass('rotate'); }, 80);
    this.model.drugCodes = '';
    this.drugCodes = [];
    this.pagedItems = [];
    this.pager = {};
    this.isResultFound = false;
    this.usertypedValueOnDrugNameSearch = null;
    this.showData = false;
  }

  public filterUnwantedData(drugCodesList: GlobalSearchViews[]): void {

    if (drugCodesList !== undefined) {
      this.drugCodes = [];
      this.selectedNavItem(3);
      this.showData = true;
      for (let obj of drugCodesList) {
        if (obj.reimb_code) {
          this.drugCodes.push(obj);
        }
        this.drugCodes.sort((a, b) => a.reimb_code.localeCompare(b.reimb_code));
        this.isResultFound = !(this.drugCodes.length > 0);
      }

      this.setPage(1);
    }
    else {
      this.isResultFound = true;
    }
  }

  public setDCode(index: number): void {

    this.selectedDrugCodes = this.pagedItems[index].reimb_code;
    this.selectedDrugCodesDescr = this.pagedItems[index].rc_descr;
    this.isFirstTimeLoad = false;
    let navigationExtras: NavigationExtras = {
      queryParams: {
        hcpccode: this.selectedDrugCodes,
        hcpcdescr: this.selectedDrugCodesDescr,
        isFromDrugName: 'true',
        rcId: this.drugCodes[index].rc_id,
        userTypedValueOnDrugNameSearch: this.usertypedValueOnDrugNameSearch,
        hcpcStatus: this.pagedItems[index].rc_status,
      },
    };
    this.router.navigate(['/layout/ndcsearch'], navigationExtras);
  }

  public setPage(page: number): void {
    if (page < 1) {
      return;
    }
    if (page !== 1 && page > this.pager.totalPages) {
      return;
    }
    this.pageindex = page;
    // get pager object from service
    this.pager = this.pagerService.getPager(this.drugCodes.length, page);

    // get current page of items
    this.pagedItems = this.drugCodes.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }

  public openModal(): void {
    this.display = 'block';
  }

  public redirectToHcpc(): void {
    //redirection code
    const i = 0;
  }

  public onCloseHandled(): void {
    if (window.name !== '' && JSON.parse(window.name).session_id !== undefined && sessionStorage.isFixedUrl === 'false') {
      this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;

      this.headerService.deleteSession(JSON.parse(window.name).session_id).subscribe(response => {
        this.clearSession();
      },
        error => {

          this.clearSession();
        });

    }
    else {
      this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
      sessionStorage.clear();
      location.replace(this.url);
    }
  }

  public clearSession(): void {
    window.name = '';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    sessionStorage.removeItem('config');
    location.replace(this.url);
  }

}
