import { Component, OnInit } from '@angular/core';
import { Event, NavigationEnd, NavigationStart, Router } from '@angular/router';

@Component({
  selector: 'app-unauth-header',
  templateUrl: './unauth-header.component.html'
})
export class UnauthHeaderComponent implements OnInit {

  public isLogin: boolean = false;
  
  constructor(
    private router: Router
  ) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        let url = event.url
        if (url.includes('access-denied-rcndcxwalk') || url.includes('invalid-signature-rcndcxwalk')) {
          this.isLogin = true
        }
        else {
          this.isLogin = false
        }
      }

      if (event instanceof NavigationEnd) {
        let url = event.url
        if (url.includes('access-denied-rcndcxwalk') || url.includes('invalid-signature-rcndcxwalk')) {
          this.isLogin = true
        }
        else {
          this.isLogin = false
        }
      }
    })
  }
  ngOnInit() {
  }

}
