import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoginService } from '../../../shared/services/login.service';

@Component({
  selector: 'app-npi-validate',
  templateUrl: './npi-validate.component.html',
  styleUrls: ['./npi-validate.component.scss']
})
export class NpiValidateComponent implements OnInit {

  public npiValue: string = '';
  public error: boolean = false;
  public errMsg: string = '';
  public isNpiValid: boolean = false;
  public npiResponse: any = [];
  public success: boolean = false;
  public successMsg: string = '';

  NpiForm = this.fb.group({
    npi: [null, Validators.required]
  })

  constructor(
    private loginService: LoginService,
    private fb: FormBuilder,
    private router: Router
  ) { }

  ngOnInit() {
    sessionStorage.getItem('registerDetails') ? sessionStorage.removeItem('registerDetails') : '';
  }

  submitData() {
    this.error = false
    this.success = false

    this.npiValue = this.NpiForm.controls.npi.value;
    this.isNpiValid = true;
    if (this.npiValue === null || this.npiValue === undefined || this.npiValue.length === 0) {
      this.error = true;
      this.errMsg = 'NPI is required.';
      this.isNpiValid = false;
    }
    if (this.isNpiValid) {
      this.success = true;
      this.successMsg = 'Authenticating...';
      console.log("NPI Submitted sucessfully: " + this.npiValue);
      this.loginService.npiOrg_lookup(this.npiValue).subscribe(res => {
        if (res.status === 200) {
          this.success = true;
          this.successMsg = 'NPI is Valid.';
          console.log(res.body[0]);
          this.npiResponse = JSON.stringify(res.body[0]);
          sessionStorage.setItem('registerDetails', this.npiResponse);
          this.validateOrg(res.body[0]['org_id']);
          // this.router.navigate(['/reg-form']);
        } else if (res.status === 204) {
          this.success = false;
          this.successMsg = '';
          this.error = true;
          this.errMsg = 'Your NPI is incorrect.';
        }
      }, () => {
        this.success = false;
        this.successMsg = '';
        this.error = true;
        this.errMsg = 'Error';
      });
    }
  }

  public validateOrg(org_id: string) {
    this.error = false
    this.success = false

    let jsonData =
    {
      "orgId": org_id, 
      "site": "3"
    }
    this.loginService.validateOrg(jsonData).subscribe(response => {

      let status = response['status'];
      if (status === 0 || status === '0') {
        this.success = true;
        this.successMsg = "Org is valid"
        this.router.navigate(['/reg-form']);

      } else if (status === '1' || status === 1) {
        this.error = true;
        this.errMsg = 'Organization has been terminated.';
      } else if (status === '2' || status === 2) {
        this.error = true;
        this.errMsg = 'No slots available in organization';
      } else {
        this.error = true;
        this.errMsg = 'Error';
      }
    }, () => {
      this.success = false;
      this.successMsg = '';
      this.error = true;
      this.errMsg = 'Error';
    });

  }
}