import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class NavService {
  // Observable navItem source
  private _navItemSource:BehaviorSubject<number> = new BehaviorSubject<number>(0);
  // Observable navItem stream
  public navItem$:Observable<number> = this._navItemSource.asObservable();
  // service command
  public changeNav(item:number): void {
    this._navItemSource.next(item);
  }
}
