import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-unauth-footer',
  templateUrl: './unauth-footer.component.html',
  styleUrls: ['./unauth-footer.component.scss']
})
export class UnauthFooterComponent implements OnInit {
  @Input() isContact: boolean = false;
  public showFooterContact: boolean = false;
  public showFooterFaq: boolean = false;

  constructor() { }

  public ngOnInit(): void {
    this.setFooterFlag();
  }

  public setFooterFlag(): void {
    if (this.isContact === true) {
      this.showFooterContact = false;
      this.showFooterFaq = true;
    }
    else {
      this.showFooterContact = true;
      this.showFooterFaq = false;
    }
  }
}
