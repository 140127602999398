import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sessiontimeout',
  templateUrl: './sessiontimeout.component.html',
})
export class SessionTimeOutComponent implements OnInit {

  constructor(private _router: Router) { }

  public ngOnInit(): void {
    //
  }


}
