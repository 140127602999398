import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { GlobalSearchViews } from '../../shared/models/global-search-views.data.model';
import { NdcCrosswalk } from '../../shared/models/ndc-crosswalk.data.model';
import { PagerService } from '../../shared/pagination/pager.service';
import { AuthorizationServices } from '../../shared/services/authorizationServices';
import { NavService } from '../../shared/services/commonServices';
import { Logger } from '../../shared/services/logger.service';
import { HeaderService } from '../layout/header.service';
import { NdcSearchService } from './ndc-search.service';

// tslint:disable-next-line: no-any
declare var $: any;
@Component({
  selector: 'app-ndc-search',
  templateUrl: './ndc-search.component.html',
  styleUrls: ['./ndc-search.component.scss'],
})
export class NdcSearchComponent implements OnInit {

  public selectedNdcCode: string;
  public selectedNdcCodeDesc: string;
  public rcId: string;
  public ndcsCodes: GlobalSearchViews[] = [];
  public model: { ndccode: string } = { ndccode: null };
  public isResultFound: boolean = false;
  public showData: boolean = false;

  // tslint:disable-next-line: no-any
  public pager: any = {};


  public pagedItems: GlobalSearchViews[] = [];

  public searchTextChanged: Subject<string> = new Subject<string>();
  public display: string = 'none';
  public selectedHcpcCodeOnHcpcSearch: string;
  public selectedHcpcDescrOnHcpcSearch: string;
  public isFirstTimeLoad: boolean = true;
  public isFromHCPCS: string = '';
  public currentPage: number = 1;
  public numPerPage: number = JSON.parse(sessionStorage['config']).numPerPage;
  public usertypedValueOnHcpc: string = null;
  public isFromSuccess: string = '';
  public usertypedValueOnNdc: string = null;
  public accountId: number;
  public isFromDrugName: string = '';
  public isFromHCpcOnPreviousClick: string;
  public isCC: boolean = false;
  public isCE: boolean = false;
  public isCG: boolean = false;
  public isCM: boolean = false;
  public isCU: boolean = false;
  public isPT: boolean = false;
  public userTypedValueOnDrugNameSearch: string;
  public isFromSuccessForDrug: string;
  public userTypedValueOnDrugNameSearchfromSucess: string = null;
  public pageindex: number;
  public hcpcStatus: string;
  private offset: number = 0;
  private limit: number = 50;
  private url: string;
  private searchByHCPC: boolean = false;
  public totalLength: number = 0;

  constructor(
    private ndcSearchService: NdcSearchService,
    private pagerService: PagerService,
    private activatedRoute: ActivatedRoute,
    private headerService: HeaderService,
    private authService: AuthorizationServices,
    private logger: Logger,
    private router: Router,
    private navsvc: NavService
  ) { }

  public ngOnInit(): void {
    //$('#ember610').addClass('active');
    this.model.ndccode = '';

    this.isResultFound = false;
    this.showData = false;

    this.accountId = JSON.parse(sessionStorage.getItem('accountDetails')).account_id;
    this.decideFeature();
    this.isFromHCPCS = this.activatedRoute.snapshot.queryParamMap.get('isFromHCpc');
    this.usertypedValueOnNdc = this.activatedRoute.snapshot.queryParamMap.get('userTypedValueOnNdc');
    this.hcpcStatus = this.activatedRoute.snapshot.queryParamMap.get('hcpcStatus');

    this.isFromHCpcOnPreviousClick = this.activatedRoute.snapshot.queryParamMap.get('isFromHCpcOnPreviousClick');
    if (this.isFromHCpcOnPreviousClick === 'true') {
      this.model.ndccode = this.usertypedValueOnNdc;
      this.getListOfNcodeValue();
    }
    if (this.isFromHCPCS === 'true') {
      this.isFirstTimeLoad = false;
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpccode');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpcdescr');
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.usertypedValueOnHcpc = this.activatedRoute.snapshot.queryParamMap.get('usertypedValue');

      this.fetchNdcCrossWalk(this.rcId);

    }
    this.isFromSuccess = this.activatedRoute.snapshot.queryParamMap.get('isFromSuccess');
    this.isFromSuccessForDrug = this.activatedRoute.snapshot.queryParamMap.get('isFromSuccessForDrug');
    if (this.isFromSuccess === 'true') {
      this.isFirstTimeLoad = false;
      this.model.ndccode = this.activatedRoute.snapshot.queryParamMap.get('UserTypedValueOnNdc');
      this.usertypedValueOnHcpc = this.activatedRoute.snapshot.queryParamMap.get('UsertypedValueOnHcpc');
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromHcpc');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromHcpc');
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.fetchNdcCrossWalk(this.rcId);
    }
    this.isFromDrugName = this.activatedRoute.snapshot.queryParamMap.get('isFromDrugName');
    if (this.isFromDrugName === 'true') {
      this.isFirstTimeLoad = false;
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpccode');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('hcpcdescr');
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.userTypedValueOnDrugNameSearch = this.activatedRoute.snapshot.queryParamMap.get('userTypedValueOnDrugNameSearch');
      this.fetchNdcCrossWalk(this.rcId);
    }
    if (this.isFromSuccessForDrug === 'true') {
      this.isFirstTimeLoad = false;
      this.selectedHcpcCodeOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcCodefromDrug');
      this.selectedHcpcDescrOnHcpcSearch = this.activatedRoute.snapshot.queryParamMap.get('selectedHcpcDescrfromDrug');
      this.userTypedValueOnDrugNameSearchfromSucess = this.activatedRoute.snapshot.queryParamMap.get('userTypedValueOnDrugNameSearchfromSucess');
      this.rcId = this.activatedRoute.snapshot.queryParamMap.get('rcId');
      this.fetchNdcCrossWalk(this.rcId);

    }
    this.isSessionActive();
    this.searchTextChanged.pipe(debounceTime(1000))

      .subscribe((ndc) => this.getNDCData(ndc));
  }

  public callOnKeyUp(e: Event): void {
    if ((e['which'] < 48 || e['which'] > 105) && e['which'] !== 8 && e['which'] !== 16) {
      return;
    }
    if (e['which'] === 91 || e['which'] === 92 || e['which'] === 93) {
      return;
    }
    this.searchTextChanged.next(e.target['value']);

  }

  public getNDCData(ndcData: string): void {
    this.model.ndccode = ndcData;
    this.ndcsCodes = [];
    this.pagedItems = [];
    this.pager = {};
    this.showData = false;
    this.selectedNavItem(2);
    this.isResultFound = false;
    if (this.model.ndccode.toString().length >= 3) {
      var allpartNdc = this.model.ndccode.toString();
      allpartNdc = allpartNdc.replace(/[^a-zA-Z 0-9]+/g, '');
      var allpartNdcWithHyphen = this.formatNdcWithHyphen(allpartNdc);

      this.usertypedValueOnNdc = allpartNdcWithHyphen;

      this.fetchNdcCrossWalkByNDC(allpartNdcWithHyphen, this.isFirstTimeLoad);

    } else if (this.model.ndccode.toString().length < 1) {
      if (!this.isFirstTimeLoad) {
        this.fetchNdcCrossWalk(this.rcId);
      }

    }
  }

  public getListOfNcodeValue(): void {
    this.searchByHCPC = false;
    this.ndcSearchService.getInitialNDCSearch(this.accountId, this.model.ndccode).subscribe(data => {
      this.filterUnwantedData(Object.assign([], data));
    }, (err: Response) => {
      this.logger.error(err['error'].message);
    }
    );
  }
  public selectedNavItem(item: number): void {

    this.navsvc.changeNav(item);

  }
  public fetchNdcCrossWalkByNDC(ndc: string, isBeginingFromNdcSearch: boolean): void {
    if (isBeginingFromNdcSearch) {
      this.searchByHCPC = false;
      this.ndcSearchService.getInitialNDCSearch(this.accountId, ndc).subscribe(res => {
        if (res && Object.keys(res).length > 0) {
          this.requestNDCCrossWalkByNDCCompleted(res, this.ndcSearchService.url);
        } else {
          this.isResultFound = true;
          return;
        }
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    } else {
      this.searchByHCPC = false;
      this.ndcSearchService.getNDCCrossWalkWithNDC(this.accountId, this.rcId, ndc, this.offset, this.limit).subscribe(response => {
        if (Object.keys(response).length > 0) {
          this.requestNDCCrossWalkByNDCCompleted(response, this.ndcSearchService.url);
        } else {
          this.isResultFound = true;
          return;
        }
      }, (err: Response) => {
        this.logger.error(err['error'].message);
      });
    }
  }
  public requestNDCCrossWalkByNDCCompleted(response: GlobalSearchViews | NdcCrosswalk, url: string): void {
    var successurl = url;
    var allPart = [];
    var parts = successurl.split('/');
    var allpartNdcWithHyphen = this.formatNdcWithHyphen(this.model.ndccode);
    setTimeout((): void => {
      if ((this.usertypedValueOnNdc === parts[parts.length - 3]) || (allpartNdcWithHyphen === parts[parts.length - 3])) {
        this.calculateResponse(response);
      } else {
        this.calculateResponse(response);
      }
    }, 100);
  }

  // tslint:disable-next-line: no-any
  public calculateResponse(response: any): void {
    if (this.isFirstTimeLoad) {
      if (response) {
        this.filterUnwantedData(response);
      }
    } else {

      if (response.XWalkViews) {

        this.filterUnwantedData(response.XWalkViews);
      } else {
        this.ndcsCodes = [];
      }
    }

    this.selectedNavItem(2);
    this.isResultFound = !(this.ndcsCodes.length > 0);

    if (this.ndcsCodes.length === 1) {
      if (!this.isFirstTimeLoad && this.isFromHCPCS === 'true') {

        this.selectedNdcCode = this.ndcsCodes[0].ndc;
        this.selectedNdcCodeDesc = this.ndcsCodes[0].drug_name;
        let navigationExtras: NavigationExtras = {
          queryParams: {
            selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
            selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
            ndccode: this.selectedNdcCode,
            ndcdescr: this.selectedNdcCodeDesc,
            isFromNdc: true,
            usertypedValueonNdc: this.model.ndccode,
            usertypedValueOnHcpc: this.usertypedValueOnHcpc,
            rcId: this.rcId,
            hcpcStatus: this.hcpcStatus,
          },
        };
        this.router.navigate(['/layout/success'], navigationExtras);
      }
      if (this.isFirstTimeLoad) {

        this.selectedNdcCode = this.pagedItems[0].ndc;
        this.selectedNdcCodeDesc = this.pagedItems[0].drug_name;
        this.isFirstTimeLoad = false;
        let navigationExtras: NavigationExtras = {
          queryParams: {
            ndccode: this.selectedNdcCode,
            ndcdescr: this.selectedNdcCodeDesc,
            isFromNdc: 'true',
            reImbCode: this.pagedItems[0].reimb_code,
            rcdescr: this.pagedItems[0].rc_descr,
            userTypedValue: this.usertypedValueOnNdc,
            rcstatus: this.pagedItems[0].rc_status,
          },
        };
        this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
      }
    }
    this.redirectForSingleRecord();
  }

  public redirectForSingleRecord(): void {
    if (!this.isFirstTimeLoad && this.isFromDrugName === 'true' && this.ndcsCodes.length === 1) {
      this.selectedNdcCode = this.ndcsCodes[0].ndc;
      this.selectedNdcCodeDesc = this.ndcsCodes[0].drug_name;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
          selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
          ndccode: this.selectedNdcCode,
          ndcdescr: this.selectedNdcCodeDesc,
          isFromNdcForDrug: true,
          usertypedValueonNdc: this.ndcsCodes[0].ndc,
          userTypedValueOnDrugNameSearch: this.userTypedValueOnDrugNameSearch,
          rcId: this.rcId,
          hcpcStatus: this.hcpcStatus,
        },
      };
      this.router.navigate(['/layout/success'], navigationExtras);
    }
  }
  public decideFeature(): void {
    var globalFeatures = JSON.parse(sessionStorage.getItem('accountDetails')).features;
    var featureCode = globalFeatures.split(',');
    for (let value of featureCode) {
      if (value === 'CC') {
        this.isCC = true;
      }
      if (value === 'CE') {
        this.isCE = true;
      }
      if (value === 'CG') {
        this.isCG = true;
      }
      if (value === 'CM') {
        this.isCM = true;
      }
      if (value === 'CU') {
        this.isCU = true;
      }
      if (value === 'PT') {
        this.isPT = true;
      }
    }
  }

  public fetchNdcCrossWalk(rcId: string): void {
    this.searchByHCPC = true;
    this.ndcSearchService.getNDCCrossWalk(this.accountId, rcId, this.offset, this.limit).subscribe(data => {
      this.filterUnwantedData(data['XWalkViews']);

    }, (err: Response) => {
      this.logger.error(err['error'].message);
    });
  }

  public clearModel(): void {
    setTimeout((): void => { $('.cancel-icon').toggleClass('rotate'); }, 80);
    this.model.ndccode = '';
    this.ndcsCodes = [];
    this.pagedItems = [];
    this.isResultFound = false;
    this.pager = {};
    this.showData = false;
  }

  public filterUnwantedData(ndcsCodesList: GlobalSearchViews[]): void {
    if (this.isFromHCpcOnPreviousClick === 'true') {
      this.selectedNavItem(2);
    }

    if (ndcsCodesList !== undefined) {
      this.ndcsCodes = [];
      this.showData = true;
      for (let obj of ndcsCodesList) {
        if (obj.ndc) {
          this.ndcsCodes.push(obj);
        }
      }


      this.isResultFound = !(this.ndcsCodes.length > 0);
      this.setPage(1);
      if (this.isFromDrugName === 'true') {
        this.redirectForSingleRecord();
      } else if (this.isFromHCPCS === 'true' && this.ndcsCodes.length === 1) {
        this.selectedNdcCode = this.ndcsCodes[0].ndc;
        this.selectedNdcCodeDesc = this.ndcsCodes[0].drug_name;
        let navigationExtras: NavigationExtras = {
          queryParams: {
            selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
            selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
            ndccode: this.selectedNdcCode,
            ndcdescr: this.selectedNdcCodeDesc,
            isFromNdc: true,
            usertypedValueonNdc: this.model.ndccode,
            usertypedValueOnHcpc: this.usertypedValueOnHcpc,
            rcId: this.rcId,
            hcpcStatus: this.hcpcStatus,
          },
        };
        this.router.navigate(['/layout/success'], navigationExtras);
      }
    }
    else {
      this.isResultFound = true;
    }
  }

  public setNDCCode(index: number): void {
    if (this.isFirstTimeLoad === true) {
      this.selectedNdcCode = this.pagedItems[index].ndc;
      this.selectedNdcCodeDesc = this.pagedItems[index].drug_name;
      this.isFirstTimeLoad = false;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          ndccode: this.selectedNdcCode,
          ndcdescr: this.selectedNdcCodeDesc,
          isFromNdc: 'true',
          reImbCode: this.pagedItems[index].reimb_code,
          rcdescr: this.pagedItems[index].rc_descr,
          rcstatus: this.pagedItems[index].rc_status,
          userTypedValue: this.usertypedValueOnNdc,
          status: this.pagedItems[index].indicator === 'T' ? 'I' : 'A',
          termDate: this.pagedItems[index].term_dt,
        },
      };
      this.router.navigate(['/layout/hcpcsearch'], navigationExtras);
    } else if (!this.isFirstTimeLoad && (this.isFromDrugName === 'true' || this.isFromSuccessForDrug === 'true')) {
      this.selectedNdcCode = this.pagedItems[index].ndc;
      this.selectedNdcCodeDesc = this.pagedItems[index].drug_name;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
          selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
          ndccode: this.selectedNdcCode,
          ndcdescr: this.selectedNdcCodeDesc,
          isFromNdcForDrug: true,
          usertypedValueonNdc: this.ndcsCodes[index].ndc,
          userTypedValueOnDrugNameSearch: this.userTypedValueOnDrugNameSearchfromSucess !== null
            ? this.userTypedValueOnDrugNameSearchfromSucess : this.userTypedValueOnDrugNameSearch,
          rcId: this.rcId,
          status: this.pagedItems[index].indicator === 'T' ? 'I' : 'A',
          hcpcStatus: this.hcpcStatus,
          termDate: this.pagedItems[index].term_dt,
        },
      };
      this.router.navigate(['/layout/success'], navigationExtras);
    }
    else {
      this.selectedNdcCode = this.pagedItems[index].ndc;
      this.selectedNdcCodeDesc = this.pagedItems[index].drug_name;
      let navigationExtras: NavigationExtras = {
        queryParams: {
          selectedHcpcCodefromHcpc: this.selectedHcpcCodeOnHcpcSearch,
          selectedHcpcDescrfromHcpc: this.selectedHcpcDescrOnHcpcSearch,
          ndccode: this.selectedNdcCode,
          ndcdescr: this.selectedNdcCodeDesc,
          isFromNdc: true,
          usertypedValueonNdc: this.model.ndccode,
          usertypedValueOnHcpc: this.usertypedValueOnHcpc,
          rcId: this.rcId,
          status: this.pagedItems[index].indicator === 'T' ? 'I' : 'A',
          hcpcStatus: this.hcpcStatus,
          termDate: this.pagedItems[index].term_dt,
        },
      };
      this.router.navigate(['/layout/success'], navigationExtras);
    }
  }


  public setPage(page: number, initialSearch: boolean=false): void {
    if (page < 1) {
      return;
    }
    if (page !== 1 && page > this.pager.totalPages) {
      return;
    }
    // get pager object from service
    this.pageindex = page;
    if (this.searchByHCPC) {
      if (this.ndcsCodes.length > 0) {
        this.totalLength = this.ndcsCodes[0].total_row_count;
        this.pager = this.pagerService.getPager(this.totalLength, page);
      }
      if (!initialSearch) {
        this.ndcSearchService.getNDCCrossWalk(this.accountId, this.rcId, ((page-1)*10), 10).subscribe(data => {

          let ndcPagedData = data['XWalkViews'];
          this.ndcsCodes = ndcPagedData;
          // get current page of items
          this.pagedItems = ndcPagedData;
        },
        error => {
          this.logger.error(error['error'].message);
        });
      }
    } else {
      this.totalLength = this.ndcsCodes.length;
      this.pager = this.pagerService.getPager(this.ndcsCodes.length, page);
    }

    // get current page of items
    this.pagedItems = this.ndcsCodes.slice(this.pager.startIndex, this.pager.endIndex + 1);
  }

  public redirectToHcpcOnPreviousClick(): void {

    let navigationExtras: NavigationExtras = {
      queryParams: {
        userTypedValue: this.usertypedValueOnHcpc,
        isFromNdcOnPreviousClick: 'true',
        isBegningNDC: 'false',
      },
    };
    this.router.navigate(['/layout/hcpcsearch'], navigationExtras);

  }

  public redirectToDrugNameOnPreviousClick(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        userTypedValue: this.userTypedValueOnDrugNameSearchfromSucess,
        isFromNdcOnPreviousClickThroughSuccess: 'true',
        isBegningNDC: 'false',

      },
    };
    this.router.navigate(['/layout/drugnamesearch'], navigationExtras);
  }

  public redirectToDrugNameOnPreviousClickFromNDC(): void {
    let navigationExtras: NavigationExtras = {
      queryParams: {
        isFromNdcOnPreviousClick: 'true',
        isBegningNDC: 'false',
        userTypedValueOnDrugNameSearchFromNdc: this.userTypedValueOnDrugNameSearch,
      },
    };
    this.router.navigate(['/layout/drugnamesearch'], navigationExtras);
  }


  public removeDuplicate(origArr: GlobalSearchViews[]): GlobalSearchViews[] {
    var newArr: GlobalSearchViews[];
    var origLen = origArr.length;
    var found, x, y;

    for (x = 0; x < origLen; x++) {
      found = undefined;
      for (y = 0; y < newArr.length; y++) {
        if (origArr[x].reimb_code === newArr[y].reimb_code && origArr[x].ndc === newArr[y].ndc) {
          found = true;
          break;
        }
      }
      if (!found) {
        if (origArr[x] === undefined) {
          continue;
        }
        newArr.push(origArr[x]);
      }
    }
    return newArr;
  }

  public formatNdcWithHyphen(allpartNdc: string): string {
    var allpartNdcWithHyphen;
    if (allpartNdc.length <= 5) {
      allpartNdcWithHyphen = allpartNdc;
    }
    if (allpartNdc.length > 5 && allpartNdc.length <= 9) {
      allpartNdcWithHyphen = allpartNdc.substring(0, 5) + '-' + allpartNdc.substring(5, allpartNdc.length);
    } else if (allpartNdc.length > 9 && allpartNdc.length <= 11) {
      allpartNdcWithHyphen = allpartNdc.substring(0, 5) + '-' + allpartNdc.substring(5, 9) + '-' + allpartNdc.substring(9, allpartNdc.length);
    }
    return allpartNdcWithHyphen;
  }

  public isSessionActive(): void {
    let sessionId;
    if (window.name) {
      sessionId = window.name ? JSON.parse(window.name)['session_id'] : '';
    } else {
      sessionId = sessionStorage.getItem('sessionId') ? sessionStorage.getItem('sessionId') : '';
    }

    if (sessionId) {
      this.authService.checkSession(sessionId).subscribe(response => {
        if (window.name) {
          if (!response.session_id && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (!response.session_id && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      }, (err: Response) => {
        if (window.name) {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && !JSON.parse(window.name).multi_logins_allowed) {
            this.openModal();
          }
        } else {
          if (err['error'].message === 'Unauthorized - Session Does Not Exist' && sessionId !== 'multi_logins_allowed') {
            this.openModal();
          }
        }
      });
    }
  }

  public openModal(): void {
    this.display = 'block';
  }

  public onCloseHandled(): void {
    if (window.name !== '' && JSON.parse(window.name).session_id !== undefined && sessionStorage.isFixedUrl === 'false') {
      this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
      this.headerService.deleteSession(JSON.parse(window.name).session_id).subscribe(response => {
        this.clearSession();
      },
        error => {
          this.clearSession();
        });
    }
    else {
      this.url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
      sessionStorage.clear();
      location.replace(this.url);
    }
  }


  public clearSession(): void {
    window.name = '';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    sessionStorage.removeItem('config');
    location.replace(this.url);
  }

}

