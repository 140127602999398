import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';

// tslint:disable-next-line: no-any
function _window(): any {
  // return the native window obj
  return window;
}

@Injectable({
  providedIn: 'root',
})
export class GlobalServiceService {

  constructor(private _http: HttpClient) { }

  // tslint:disable-next-line: no-any
  get nativeWindow(): any {
    return _window();
  }

  // tslint:disable-next-line: no-any
  public getConfig(): Observable<any> {
//return this._http.get('../../rcndccrosswalk/assets/config/config.json');
    return this._http.get('./assets/config/config.json', {
      headers: { 'samesite': 'None', 'secure': 'true' },
    });
  }
}


