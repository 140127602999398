import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { EnvironmentData } from '../../shared/models/environment.data.model';
import { GlobalSearchViews } from '../../shared/models/global-search-views.data.model';
import { NdcCrosswalk } from '../../shared/models/ndc-crosswalk.data.model';
@Injectable({
  providedIn: 'root',
})
export class NdcSearchService {

  public url: string = '';
  public headers: HttpHeaders = null;
  public config: EnvironmentData;
  constructor(private http: HttpClient) { }

  private setHeaders(): HttpHeaders {
    this.config = JSON.parse(sessionStorage.getItem('config'));
    let apiKey = sessionStorage.getItem('ak')
    return this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json')
      .set('x-api-key', apiKey)
      .set('Authorization', sessionStorage.getItem('uid') ? sessionStorage.getItem('uid') : this.config.Authorization);
  }


  public getInitialNDCSearch(accountId: number, ndc: string): Observable<GlobalSearchViews> {
    const headers = this.setHeaders();
    var url = this.config.ServiceUrl + '/gsv/' + 'I' + '/' + '0' + '/' + '0/' + '1000/' + ndc;
    this.url = url;
    return this.http.get(url, { headers: headers });
  }

  public getNDCCrossWalk(accountId: number, rcId: string, offset: number, limit: number): Observable<NdcCrosswalk> {
    let headers = this.setHeaders();
    var url = this.config.ServiceUrl + '/xwalk/' + rcId + '/' + offset + '/' + limit;
    this.url = url;
    return this.http.get(url, { headers: headers });
  }

  public getNDCCrossWalkWithNDC(accountId: number, rcId: string, ndc: string, offset: number, limit: number): Observable<NdcCrosswalk> {
    let headers = this.setHeaders();
    var url = this.config.ServiceUrl + '/xwalk/' + rcId + '/' + ndc + '/' + offset + '/' + limit;
    this.url = url;
    return this.http.get(url, { headers: headers });
  }
}
