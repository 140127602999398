import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { Router, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class CheckIdleTimeService {

  constructor(private router: Router) { }

  public _userActionOccured: Subject<void> = new Subject();
  get userActionOccured(): Observable<void> { return this._userActionOccured.asObservable(); }

  public notifyUserAction():void {
    this._userActionOccured.next();
  }

  public loginUser(): void {
    //console.log('user login');
  }

  public logOutUser(): void {
    //console.log('user logout');
    var url = JSON.parse(sessionStorage['config']).amaApplicationUrl;
    window.name = '';
    sessionStorage.removeItem('isValidUser');
    sessionStorage.removeItem('response');
    sessionStorage.removeItem('currentUser');
    sessionStorage.removeItem('encKey');
    sessionStorage.removeItem('config');
    this.router.navigate(['/layout/sessiontimeout']);
    this.router.navigate(['/']);
    // location.replace(url);
  }
}
